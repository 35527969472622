// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// Initialize Firebase
// getApps()?.length > 0 ? getApps()[0] :
// const app = initializeApp(firebaseConfig);

let app: FirebaseApp = getApps()?.[0];

if (!app) {
    if (process.env.REACT_APP_ENV === "production") {
        const firebaseConfig = {
            apiKey: "AIzaSyAOteJkCRFtdgiBrngTCRW11jlO_rAlk7Y",
            authDomain: "eventlint.firebaseapp.com",
            databaseURL: "https://eventlint.firebaseio.com",
            projectId: "eventlint",
            storageBucket: "eventlint.appspot.com",
            messagingSenderId: "752381095412",
            appId: "1:752381095412:web:e6425fcfd559ef6c111da1",
            measurementId: "G-XYHY1PLDPX"
        };
        app = initializeApp(firebaseConfig);
    } else {
        const firebaseConfig = {
            apiKey: "AIzaSyBT4rz8pCmcvUFc9FjNkDxF06e8FgQ1zRM",
            authDomain: "eventlint-staging.firebaseapp.com",
            projectId: "eventlint-staging",
            storageBucket: "eventlint-staging.appspot.com",
            messagingSenderId: "760777289073",
            appId: "1:760777289073:web:2c4e733c59a652949cdad0",
            measurementId: "G-4N3Z5HYM8C"
        };
        app = initializeApp(firebaseConfig);

        if (process.env.REACT_APP_EMULATOR_FUNCTIONS) {
            connectFunctionsEmulator(getFunctions(app), "localhost", Number(process.env.REACT_APP_EMULATOR_FUNCTIONS));
        }
        if (process.env.REACT_APP_EMULATOR_FIRESTORE) {
            connectFirestoreEmulator(getFirestore(app), "localhost", Number(process.env.REACT_APP_EMULATOR_FIRESTORE));
        }
    }
}

// try {
//     initializeAnalytics(app);
// } catch (err) {
//     console.log("err initializing analytics", err);
//     console.log(err);
// }

const analytics = getAnalytics(app);
const db = getFirestore(app);

export { app, analytics, db };

import './styles/App.scss';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import WeeklyPage from './pages/weekly';
import Presentation from './pages/presentation';
import logo from './assets/eventlint-logo.svg';
import React from 'react';
import {
  analytics
} from './config';
import {
  logEvent,
} from 'firebase/analytics';
import ProductUpdates from './pages/updates';
import Overview from './pages/overview';
import { useAuth } from './hooks/AuthLogin';
import { Button, IconButton } from '@mui/material';

import MobileMenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function App() {
  const [showMenu, setShowMenu] = React.useState(false);
  const auth = useAuth();
  const location = useLocation()

  React.useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
    // runs on location, i.e. route, change
    console.log('handle route change here', location)
  }, [location])

  const handleNavClick = () => {
    setShowMenu(false);
  }

  const handleLogout = () => {
    setShowMenu(false);
    auth.logout();
  }


  return (
    <div className="page-wrapper">
      <header className=" flex flex-row items-center p-4 justify-between">
        <div>
          <Link to="/overview">
            <img src={logo} alt="eventlint" />
          </Link>
        </div>
        <nav className={`flex gap-8 mobile-menu ${showMenu ? 'show' : ''}`}>
          <Link onClick={handleNavClick} to="/weekly">Weekly Updates</Link>
          <Link onClick={handleNavClick} to="/updates">MVP Updates</Link>
          <Link onClick={handleNavClick} to="/presentation">Presentation</Link>
          <Button onClick={handleLogout} className="mobile-nav-item">Logout</Button>
          <div className='mobile-menu-close'>
            <IconButton onClick={() => setShowMenu(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </nav>

        <div className='mobile-menu-button'>
          <IconButton onClick={() => setShowMenu(!showMenu)}>
            <MobileMenuIcon />
          </IconButton>




        </div>

      </header>
      <Routes>
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/weekly" element={<WeeklyPage />} />
        <Route path="/updates" element={<ProductUpdates />} />
        <Route path="/" element={<Overview />} />
      </Routes>

      <footer className="text-sm p-3">
        <div className="">
          All materials in this presentation are confidential and may not be shared, or otherwise distributed or used in any form without the express permission of eventlint.
        </div>
        <div className={`text-center logout-btn`}>
          <Button variant="text" onClick={handleLogout}>Logout</Button>
        </div>
        <div className="text-center text-xs pt-4 text-gray-400">
          Copyright {new Date().getFullYear()} eventlint, Inc. All Rights Reserved.
          &#8226;	{process.env.REACT_APP_ENV}
          &#8226;	{process.env.NODE_ENV}
          &#8226;	{process.env.REACT_APP_VERSION}
        </div>
      </footer>
    </div>


  );
}

export default App;
